<template>
  <div>
    <p>
      {{ $t("auth.dont_have") }}
      <button @click="openSignup">{{ $t("auth.signup") }}</button>
    </p>
    <v-dialog v-model="signupAuth" width="600px" class="modal_auth">
      <v-card class="dialog_auth">
        <v-container>
          <div class="dialog_title d-flex justify-content-center align-items-center flex-column position-relative">
            <v-card-title class="font-weight-bold">
              {{ $t("auth.signup") }}
            </v-card-title>
            <v-card-text color="#868E96" style="color: #868e96">
              {{ $t("auth.Signdesc") }}
            </v-card-text>
            <hr style="margin: 0 !important; color: #868e96"/>

            <div class="position-absolute" style="left: 5%;top: 10px;">
              <a href="javascript:void(0);" @click.prevent="signupAuth = false;">
                <i class="mdi mdi-close mdi-24px"></i>
              </a>
            </div>
          </div>
          <form style="margin-top: 64px">
            <v-row>
              <v-col cols="12" md="6">
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.firstname") }}
                </p>
                <v-text-field
                    v-model="Firstname"
                    :error-messages="FirstnameErrors"
                    :placeholder="$t('auth.firstname')"
                    required
                    outlined
                    @input="$v.Firstname.$touch()"
                    @blur="$v.Firstname.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.lastname") }}
                </p>
                <v-text-field
                    v-model="Lastname"
                    :error-messages="LastnameErrors"
                    :placeholder="$t('auth.lastname')"
                    required
                    outlined
                    @input="$v.Lastname.$touch()"
                    @blur="$v.Lastname.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.email") }}
                </p>
                <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    :placeholder="$t('auth.email')"
                    required
                    outlined
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <p class="d-flex justify-start items-start mb-3">
                  {{ $t("auth.country") }}
                </p>
                <v-select class="country-select" :items="countries" v-model="country_id" item-value="id"
                          :label="$t('auth.choose-country')" :error-messages="countryIdErrors">
                  <template v-slot:selection="{ item, index }">
                    <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                    &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                    &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col md="12" cols="12">
                <div
                    class="d-flex justify-content-start align-items-start flex-column w-100 form position-relative mobile mb-2"
                    style="gap: 10px;">
                  <span>{{ $t('auth.phone') }}</span>
                  <v-text-field dir="ltr" v-model.trim="mobile" required outlined class="w-100"
                                :placeholder="$t('auth.phone')" :error-messages="PhoneErrors"
                                @input="$v.mobile.$touch()" @blur="$v.mobile.$touch()"
                                @keydown.space.prevent></v-text-field>
                </div>
              </v-col>
              <!--              <v-col md="6" cols="12">-->
              <!--                <p class="d-flex justify-start items-start">-->
              <!--                  {{ $t("auth.Password") }}-->
              <!--                </p>-->
              <!--                <v-text-field-->
              <!--                    v-model="password"-->
              <!--                    :error-messages="passowrdErrors"-->
              <!--                    :placeholder="$t('auth.Password')"-->
              <!--                    :type="hiddenPassword2 ? 'text' : 'password'"-->
              <!--                    required-->
              <!--                    outlined-->
              <!--                    @input="$v.password.$touch()"-->
              <!--                    @blur="$v.password.$touch()"-->
              <!--                >-->
              <!--                  <v-icon-->
              <!--                      v-if="hiddenPassword2"-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword2 = !hiddenPassword2"-->
              <!--                  >mdi-eye-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                  <v-icon-->
              <!--                      v-else-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword2 = !hiddenPassword2"-->
              <!--                  >mdi-eye-off-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                </v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col md="6" cols="12">-->
              <!--                <p class="d-flex justify-start items-start">-->
              <!--                  {{ $t("auth.confirmPass") }}-->
              <!--                </p>-->
              <!--                <v-text-field-->
              <!--                    v-model="passwordConfimation"-->
              <!--                    :error-messages="passowrdConfirmErrors"-->
              <!--                    :placeholder="$t('auth.confirmPass')"-->
              <!--                    :type="hiddenPassword ? 'text' : 'password'"-->
              <!--                    required-->
              <!--                    outlined-->
              <!--                    @input="$v.passwordConfimation.$touch()"-->
              <!--                    @blur="$v.passwordConfimation.$touch()"-->
              <!--                >-->
              <!--                  <v-icon-->
              <!--                      v-if="hiddenPassword"-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword = !hiddenPassword"-->
              <!--                  >mdi-eye-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                  <v-icon-->
              <!--                      v-else-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword = !hiddenPassword"-->
              <!--                  >mdi-eye-off-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                </v-text-field>-->
              <!--              </v-col>-->
            </v-row>
            <div class="d-flex justify-start items-start gap-2 mx-0 mt-4">
              <v-checkbox
                  v-model="checkbox"
                  color="#007FED"
                  :error-messages="checkboxErrors"
                  required
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                  class="mx-0"
              >
              </v-checkbox>
              <label for="" style="font-size: 14px">
                {{ $t("auth.agree") }}
                <router-link to="/agreement" style="color: #007fed !important">
                  {{ $t("auth.privacy") }}
                </router-link>
                {{ $t("auth.and") }}
                <router-link to="/terms" style="color: #007fed !important">
                  {{ $t("auth.policy") }}
                </router-link>
              </label>
            </div>
            <div class="row text-start mt-3 mb-8">
              <div class="col-12 col-sm-6">
                <input class="form-check-input" type="radio" v-model="verify_type" id="verify-type-sms" value="sms"
                       checked>&nbsp;&nbsp;
                <label class="form-check-label" for="verify-type-sms">{{ $t('mobile-verify') }}</label>
              </div>
              <div class="col-12 col-sm-6">
                <input class="form-check-input" type="radio" v-model="verify_type" id="verify-type-whatsapp"
                       value="whatsapp">&nbsp;&nbsp;
                <label class="form-check-label" for="verify-type-whatsapp">{{ $t('whatsapp-verify') }}</label>
              </div>
            </div>
            <div class="flex justift-start items-start" style="height: 40px; margin-bottom: 48px">
              <v-btn class="submit" :loading="loading" @click="Handlesignup" color="#000">
                {{ $t("auth.submit") }}
              </v-btn>
            </div>
            <hr style="margin-bottom: 0 !important; width: 100%"/>
          </form>
          <v-spacer></v-spacer>
          <div
              class="mt-5 d-flex justify-content-center align-items-center flex-column Ques_auth"
          >
            <p class="mb-0">
              {{ $t("auth.have") }}
              <button @click="openLogin">{{ $t("auth.login") }}</button>
            </p>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="verifyAccDialog" width="600px" class="modal_auth">
      <v-card class="dialog_auth">
        <div class="dialog_title d-flex justify-content-center align-items-center flex-column position-relative">
          <v-card-title class="font-weight-bold">
            تسجيل حساب جديد
          </v-card-title>
          <v-card-text v-if="ifSendWhatsapp" color="#6c757d">{{ $t('auth.add-verify-whatsapp') }}</v-card-text>
          <v-card-text v-else color="#6c757d">{{ $t('auth.addverifyMoile') }}</v-card-text>
          <hr/>

          <div class="position-absolute" style="left: 5%;top: 10px;">
            <a href="javascript:void(0);" @click.prevent="verifyAccDialog = false;">
              <i class="mdi mdi-close mdi-24px"></i>
            </a>
          </div>
        </div>
        <form>
          <v-container>
            <div>
              <p class="d-flex justify-start items-start">
                {{ $t("auth.verify") }}
              </p>
              <v-text-field
                  v-model="verify"
                  :placeholder="$t('auth.add-verify')"
                  required
                  outlined
              ></v-text-field>
            </div>
            <div class="flex justift-start items-start mt-4">
              <v-btn
                  class="submit"
                  :loading="loading"
                  @click="HandleVerify"
                  color="#000"
              >
                {{ $t("auth.submit") }}
              </v-btn>
            </div>
          </v-container>
          <hr style="margin-bottom: 24px !important; width: 100%"/>
        </form>
        <v-spacer></v-spacer>
        <div
            class="mb-3 p-3 d-flex justify-content-center align-items-center flex-column Ques_auth"
        >
          <p>
            {{ $t("auth.problem") }}
            <router-link to="/contact-us">
              {{ $t("auth.contactUs") }}
            </router-link>
          </p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {signupMixin} from "../../rules.js";

export default {
  mixins: [signupMixin],
  props: ['dialogAuth'],
  data() {
    return {
      signupAuth: false,
      dialogAuth: true,
      verifyAccDialog: false,
      ifSendWhatsapp: false,
      checkbox: false,
      loading: false,
      // hiddenPassword: false,
      // hiddenPassword2: false,
      country_id: null,
      verify_type: 'sms',
    };
  },
  computed: {
    signUpPAYLOAD() {
      const PAYLOAD = {
        first_name: this.Firstname,
        last_name: this.Lastname,
        email: this.email,
        country_id: this.country_id,
        mobile: this.mobile,
        // password: this.password,
        // password_confirmation: this.passwordConfimation,
        agree_terms: this.checkbox,
        verify_type: this.verify_type,
      };
      localStorage.setItem("country_id", this.country_id);
      localStorage.setItem("mobile", this.mobile);
      localStorage.setItem("email", this.email);
      return {...PAYLOAD};
    },
    verifyAccPAYLOAD() {
      const country_id = localStorage.getItem("country_id");
      const mobile = localStorage.getItem("mobile");
      const PAYLOAD = {
        country_id: country_id,
        mobile: mobile,
        code: this.verify,
      };
      return {...PAYLOAD};
    },
    ...mapGetters([
      "GetSignUpMessage",
      "GetSmsType",
      "GetVerifyMessage",
      "GetVerifyError",
      "GetSignUpMessageError",
    ]),
    ...mapGetters("home", ["getCountries"]),
    countries() {
      return this.getCountries;
    },
  },
  methods: {
    // openSignup() {
    //   this.$emit('update:dialogAuth', true); // Emit an event to request a change in the parent component
    // },
    Handlesignup() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.$store
          .dispatch("handleSignUp", this.signUpPAYLOAD)
          .then(() => {
            this.loading = false;
            this.verifyAccDialog = true;
            this.ifSendWhatsapp = (this.GetSmsType === 'whatsapp');

            this.resetSignUp();
            this.TriggerNotify("success", this.GetSignUpMessage);
          })
          .catch((error) => {
            this.TriggerNotify("error", error.response.data.message);
            this.loading = false;
          });
    },
    HandleVerify() {
      this.$v.$touch();
      this.loading = true;
      this.$store.dispatch("handleVerifyAcc", this.verifyAccPAYLOAD)
          .then(() => {
            this.loading = false;
            this.resetVerifyAcc();
            this.TriggerNotify("success", this.GetVerifyMessage);
          })
          .catch((e) => {
            this.loading = false;
            this.TriggerNotify("error", e.response.data.message);
            this.TriggerNotify("error", e.response.data.error);
          });
    },
    openSignup() {
      this.signupAuth = true;
      this.$emit('update:dialogAuth', false);
    },
    openLogin() {
      this.signupAuth = false;
      this.$emit('update:dialogAuth', true);
    },
    resetSignUp() {
      this.Firstname = false;
      this.Lastname = false;
      this.email = false;
      // this.password = false;
      // this.passwordConfimation = false;
      this.checkbox = false;
      this.signupAuth = false;
      this.verify_type = 'sms';
      this.$v.$reset();
    },
    resetVerifyAcc() {
      this.verify = false;
      this.verifyAccDialog = false;
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.saudi-num {
  position: absolute;
  top: 34px;
  left: 12px;
  z-index: 9;
  background: #fafafa;
  padding: 6px 22px;
  border-radius: 5px;

  span {
    margin-inline-end: 5px;
  }
}

.mobile {
  input {
    &::placeholder {
      text-align: end !important;
    }
  }
}
</style>
