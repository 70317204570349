<template>
  <section class="cine_academy_details">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div v-if="studioDetails">
        <div class="details__">
          <v-row>
            <v-col cols="12" md="6">
              <div class="details_img">
                <img style="cursor: pointer;" :src="studioDetails.image_700_url" :alt="studioDetails.name" id="largeImg"
                     @click.prevent="openLightbox(startAt)"/>
              </div>
              <div>
                <div class="__studio_imgs mt-3">
                  <div style="cursor: pointer;" v-for="(img, index) in media" :key="index" class="position-relative">
                    <img :src="img.thumb" height="115" width="200" :alt="studioDetails.name" class="small-Img"
                         @click.prevent="updateLargeImg(img.outside_image);startAt = index;"/>
                    <i v-if="img.type === 'video'" @click.prevent="openLightbox(index)"
                       class="mdi mdi-play-circle position-absolute text-white"
                       style="font-size: 50px;top: calc(50% - 43px);right: calc(50% - 25px);"></i>
                  </div>
                </div>
                <LightBox :media="media" :startAt="startAt" v-if="Openlightbox" @onClosed="closeLightbox"></LightBox>
              </div>

              <div class="details_info d-flex justify-content-start align-start flex-column">
                <div class="d-flex justify-content-start align-start flex-row gap-3">
                  <span>{{ $t("studios.city") }}</span>
                  <span> {{ studioDetails.city_name + ' / ' + studioDetails.district_name }} </span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3">
                  <span>{{ $t("studios.categories") }}</span>
                  <span> {{ studioDetails.category_name }} </span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3">
                  <span>{{ $t("studios.space") }}</span>
                  <span> {{ studioDetails.area_in_metres }} {{ $t('studios.meter') }} </span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3">
                  <span>{{ $t('studios.price') }}</span>
                  <span>
                    {{ studioDetails.rent_price }}
                  </span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3">
                  <span>{{ $t('studios.insurance_amount') }}</span>
                  <span>{{ studioDetails.insurance_amount }}</span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3"
                     v-if="studioDetails.rent_type === 'day'">
                  <span>{{ $t('received_at') }}</span>
                  <span>{{ studioDetails.received_at }}</span>
                </div>
                <div class="d-flex justify-content-start align-start flex-row gap-3"
                     v-if="studioDetails.rent_type === 'day'">
                  <span>{{ $t('delivered_at') }}</span>
                  <span>{{ studioDetails.delivered_at }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="details_rating d-flex justify-content-start align-center gap-2 flex-row">
                <v-rating v-model.number="floatTotalRates" background-color="#ccc" color="#FC7D12" dense
                          readonly size="16"></v-rating>
                <span> ({{ studioDetails.totalRates }}) </span>
                <span>
                  {{ studioDetails.totalRatesCount }} {{ $t("studios.review") }}
                </span>
              </div>
              <div class="details_desc">
                <h1>{{ studioDetails.name }}</h1>
              </div>
              <div class="details_price d-flex align-items-center gap-3 align-center flex-row my-1">
                <div>{{ studioDetails.rent_price }}</div>
                <div class="show-offer" v-if="studioDetails.offer !== ''">{{ studioDetails.offer }}</div>
              </div>

              <div class="d-flex text-start flex-column" v-if="studioDetails.tags.length">
                <a href="javascript:void(0);" @click.prevent="showAllTags = true">
                  <span v-for="tag in studioDetails.tags" :key="tag.id" class="badge px-6 py-2 m-1 fw-medium text-start"
                        style="font-size: 16px;text-wrap: wrap;"
                        :style="'background-color:' + tag.info.color + '25; color:' + tag.info.color">
                    <img class="img-fluid" :src="tag.info.icon_path" :alt="tag.info.name"
                         style="width: 20px; height: 20px;object-fit: contain;">
                    &nbsp;&nbsp;<span class="fw-bold">{{ tag.info.name }}</span>
                    &nbsp;&nbsp;<span>{{ tag.info.description }}</span>
                  </span>
                </a>
              </div>

              <v-row class="mb-4">
                <v-col cols="12" md="6">
                  <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio mt-5">
                    <span> {{ $t("studios.StartDate") }} </span>
                    <v-menu v-model="Startbook"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="Startdate"
                                      :placeholder="$t('choose-date')"
                                      readonly
                                      outlined
                                      v-bind="attrs"
                                      v-on="on"
                                      class="w-100"
                                      color="#e0e0e0"
                                      style="border-color: red"
                                      hide-details>
                          <template #prepend-inner>
                            <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                          :locale="lang"
                          :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                          v-model="Startdate"
                          @input="Startbook = false"
                          @change="getDayWorkTimes($event, 'start');"
                          :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                    <div v-for="item in errorStartDate"
                         :key="item"
                         v-if="errorStartDate">
                      <span class="datetime-error">{{ item }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio mt-5">
                    <span> {{ $t("studios.endDate") }} </span>
                    <v-menu v-model="Endbook"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="Enddate"
                                      :placeholder="$t('choose-date')"
                                      readonly
                                      outlined
                                      v-bind="attrs"
                                      v-on="on"
                                      class="w-100"
                                      hide-details>
                          <template #prepend-inner>
                            <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                          :locale="lang"
                          :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                          v-model="Enddate"
                          @input="Endbook = false"
                          @change="getDayWorkTimes($event, 'end');"
                          :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                    <div v-for="item in errorEndDate"
                         :key="item"
                         v-if="errorEndDate">
                      <span class="datetime-error">{{ item }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" :class="{'d-none': studioDetails.rent_type === 'day'}">
                  <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio">
                    <span> {{ $t("studios.StartTime") }} </span>
                    <v-select class="w-100 mt-0 pt-0"
                              item-text="text"
                              item-value="value"
                              v-model="Starttime"
                              :items="start_clock_hours">
                    </v-select>
                    <div v-for="item in errorStartTime"
                         :key="item"
                         v-if="errorStartTime">
                      <span class="datetime-error">{{ item }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" :class="{'d-none': studioDetails.rent_type === 'day'}">
                  <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio">
                    <span> {{ $t("studios.EndTime") }} </span>
                    <v-select class="w-100 mt-0 pt-0"
                              item-text="text"
                              item-value="value"
                              v-model="Endtime"
                              :items="end_clock_hours">
                    </v-select>
                    <div v-for="item in errorEndTime"
                         :key="item"
                         v-if="errorEndTime">
                      <span class="datetime-error">{{ item }}</span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="12">
                  <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio">
                    <span>{{ $t('discount-code') }}</span>
                    <div class="input-group">
                      <input type="text" class="form-control" :placeholder="$t('discount-code')" v-model="coupon.code"
                             @input="checkCoupon">
                      <span class="input-group-text">
                        <v-icon :class="(coupon.ifSuccess) ? 'custom-success' : ''">mdi-check</v-icon>
                      </span>
                    </div>
                    <div>
                      <span class="datetime-error">{{ coupon.errorMessage }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="text-start text-danger">
                    {{ studioDetails.insurance_statement }}
                  </div>
                </v-col>

                <v-col cols="12" md="12" v-if="studioDetails.facilities.length">
                  <div class="facilities-div">
                    <template>
                      <div class="d-flex justify-content-between mb-2">
                        <label>{{ $t('facilities') }}</label>
                        <button @click="toggleCloseMenu" class="btn btn-dark px-8" v-if="chooseDoneShow">
                          <i class="mdi mdi-check-all"></i>&nbsp;&nbsp;{{ $t('choose-done') }}
                        </button>
                      </div>
                    </template>
                    <v-select
                        :items="studioDetails.facilities"
                        :item-text="(facility) => facility.info.name+' => '+facility.price_with_currency"
                        :item-value="(facility) => facility.id"
                        v-model="chosenFacilities"
                        multiple filled solo :label="$t('facilities')" prepend-inner-slot
                        :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                        :no-data-text="$t('no-data')"
                        @click="chooseDoneShow = true;">
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggleCheckAll">
                          <v-list-item-action>
                            <v-icon>{{ icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('choose-all') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                    <!--                    <v-select-->
                    <!--                        :items="studioDetails.facilities" item-value="id"-->
                    <!--                        v-model="chosenFacilities"-->
                    <!--                        multiple filled solo :label="$t('facilities')" prepend-inner-slot-->
                    <!--                        :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"-->
                    <!--                        :no-data-text="$t('no-data')"-->
                    <!--                        @click="chooseDoneShow = true;">-->
                    <!--                      <template v-slot:prepend-item>-->
                    <!--                        <v-list-item ripple @mousedown.prevent @click="toggleCheckAll">-->
                    <!--                          <v-list-item-action>-->
                    <!--                            <v-icon>{{ icon }}</v-icon>-->
                    <!--                          </v-list-item-action>-->
                    <!--                          <v-list-item-content>-->
                    <!--                            <v-list-item-title>-->
                    <!--                              {{ $t('choose-all') }}-->
                    <!--                            </v-list-item-title>-->
                    <!--                          </v-list-item-content>-->
                    <!--                        </v-list-item>-->
                    <!--                        <v-divider class="mt-2"></v-divider>-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:selection="{ item, index }">-->
                    <!--                        {{ item.info.name }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item="{ item }">-->
                    <!--                        <div class="row justify-content-between align-items-center">-->
                    <!--                          <div class="col-auto">-->
                    <!--                            <img class="img-fluid feature-image" :src="item.info.icon_path" :alt="item.info.name"/>-->
                    <!--                            &nbsp;{{ item.info.name }}-->
                    <!--                          </div>-->
                    <!--                          <div class="col-auto">{{ item.price_with_currency }}</div>-->
                    <!--                        </div>-->
                    <!--                      </template>-->
                    <!--                    </v-select>-->
                  </div>
                </v-col>

                <v-col cols="12" md="12" v-if="studioDetails.order_pay_type === 'both'">
                  <div class="date_studio text-start">
                    <span>{{ $t('payment-way') }}</span>
                    <div class="row mt-0">
                      <div class="col-6">
                        <input class="form-check-input" type="radio" v-model="order_pay_type" id="deposit"
                               value="deposit">&nbsp;&nbsp;
                        <label class="form-check-label" for="deposit">{{ $t('deposit') }}</label>
                      </div>
                      <div class="col-6">
                        <input class="form-check-input" type="radio" v-model="order_pay_type" id="full_money"
                               value="full_money" checked>&nbsp;&nbsp;
                        <label class="form-check-label" for="full_money">{{ $t('full-money') }}</label>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <div class="details_btns d-flex gap-3 flex-row justify-content-end align-end flex-wrap mt-3">
                <button class="btn" @click="copyURL">
                  <v-icon> mdi-content-copy</v-icon>
                  {{ $t("studios.copyUrl") }}
                </button>
                <button @click="toggleFavorite"
                        v-if="studioDetails.isFav == false"
                        :loading="loading">
                  <v-icon color="red">
                    {{ isFavorite ? "mdi-heart" : "mdi-heart-outline" }}
                  </v-icon>
                  {{
                    isFavorite
                        ? $t("studios.removeFromFav")
                        : $t("studios.addToFav")
                  }}
                </button>
                <button @click="toggleUnFavorite"
                        v-if="studioDetails.isFav == true"
                        :loading="loading">
                  <v-icon color="red">
                    {{ isUnFavorite ? "mdi-heart-outline" : "mdi-heart" }}
                  </v-icon>
                  {{
                    isUnFavorite
                        ? $t("studios.addToFav")
                        : $t("studios.removeFromFav")
                  }}
                </button>
                <button class="btn" @click="openChat('admin')">
                  <v-icon>mdi-chat-processing-outline</v-icon>
                  {{ $t("admin-chat") }}
                </button>
                <button class="btn" @click="openChat('member')">
                  <v-icon>mdi-chat-processing-outline</v-icon>
                  {{ $t("provider-chat") }}
                </button>
                <v-btn class="btn" @click="getOrderSummary" :disabled="statusOrder" :loading="loadingOffer">
                  <v-icon> mdi-cart-outline</v-icon>
                  {{ $t("studios.orderNow") }}
                </v-btn>
                <!--                <v-alert v-if="statusOrder" dense text type="success" class="m-0">-->
                <!--                  {{ $t("studios.pendingOrder") }}-->
                <!--                </v-alert>-->
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="cine_academy_desc mt-5 mb-5">
          <v-card>
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab>{{ $t("studios.desc") }}</v-tab>
              <v-tab>{{ $t("studios.tools") }}</v-tab>
              <v-tab>{{ $t("studios.time") }}</v-tab>
              <v-tab>{{ $t("studios.review") }}</v-tab>
              <v-tab>{{ $t('features') }}</v-tab>
              <v-tab>{{ $t('map-location') }}</v-tab>
              <v-tab>{{ $t('space-terms') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text class="mx-5" v-html="studioDetails.description">
                  </v-card-text>
                  <v-card-text v-if="studioDetails.description === ''">
                    {{ $t("studios.noContent") }}
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text class="mx-5" v-html="studioDetails.tools"></v-card-text>
                  <v-card-text v-if="studioDetails.tools === ''">
                    {{ $t("studios.noContent") }}
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text>
                    <h6 class="mb-3">{{ $t('studios.times.time') }}:</h6>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.saturday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.sat') }} : </span>
                      <div>
                        {{ studioDetails.working_days.saturday_time_from }} -
                        {{ studioDetails.working_days.saturday_time_to }}
                      </div>
                    </div>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.sunday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.sun') }} : </span>
                      <div>
                        {{ studioDetails.working_days.sunday_time_from }} -
                        {{ studioDetails.working_days.sunday_time_to }}
                      </div>
                    </div>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.monday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.mon') }} : </span>
                      <div>
                        {{ studioDetails.working_days.monday_time_from }} -
                        {{ studioDetails.working_days.monday_time_to }}
                      </div>
                    </div>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.tuesday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.tue') }} : </span>
                      <div>
                        {{ studioDetails.working_days.tuesday_time_from }} -
                        {{ studioDetails.working_days.tuesday_time_to }}
                      </div>
                    </div>
                    <div class="work_time_days d-flex justify-content-between align-items-center flex-row"
                         style="gap: 12px; margin-inline-start: 25px"
                         v-if="studioDetails.working_days.wednesday_available === 'available'">
                      <span> {{ $t('studios.times.wed') }} : </span>
                      <div>
                        {{ studioDetails.working_days.wednesday_time_from }} -
                        {{ studioDetails.working_days.wednesday_time_to }}
                      </div>
                    </div>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.thursday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.thru') }} : </span>
                      <div>
                        {{ studioDetails.working_days.thursday_time_from }} -
                        {{ studioDetails.working_days.thursday_time_to }}
                      </div>
                    </div>
                    <div
                        class="work_time_days d-flex justify-content-between align-items-center flex-row"
                        style="gap: 12px; margin-inline-start: 25px"
                        v-if="
                        studioDetails.working_days.friday_available ===
                        'available'
                      "
                    >
                      <span> {{ $t('studios.times.fri') }} : </span>
                      <div>
                        {{ studioDetails.working_days.friday_time_from }} -
                        {{ studioDetails.working_days.friday_time_to }}
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-text v-if="studioDetails.work_times == ''">
                    {{ $t("studios.noContent") }}
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text>
                    <div class="card mb-3" v-for="(item, index) in studioDetails.rates" :key="index">
                      <div class="row g-0 align-items-start py-2 px-3">
                        <div class="col-auto">
                          <img class="img-fluid rate-image" :src="item.member_image" :alt="item.member_name">
                        </div>
                        <div class="col">
                          <div class="row justify-content-between align-items-center">
                            <div class="col-auto">{{ item.member_name }}</div>
                            <div class="col-auto">
                              <div class="review-stars d-flex align-items-center">
                                <v-rating v-model.number="item.rate" color="#fc7d12" background-color="grey darken-1"
                                          hover dense readonly large size="12"></v-rating>
                                <small>({{ item.rate + '.0' }})</small>
                              </div>
                            </div>
                          </div>
                          <span>{{ item.comment }}</span>
                        </div>
                      </div>
                    </div>
                    <v-card-text v-if="!studioDetails.rates.length">
                      {{ $t("studios.noContent") }}
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text>
                    <div class="row gap-3 m-2">
                      <div class="col-12 col-sm-auto" v-for="(item, index) in studioDetails.features" :key="index">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <img class="img-fluid feature-image" :src="item.info.icon_path" :alt="item.info.name">
                          </div>
                          <div class="col-auto">
                            <span>{{ item.info.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-card-text v-if="!studioDetails.features.length">
                      {{ $t("studios.noContent") }}
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text>
                    <div class="row m-2" v-if="studioDetails.map_location !== ''">
                      <div class="col-12">
                        <GmapMap
                            :center="{lat:parseFloat(studioDetails.latitude), lng:parseFloat(studioDetails.longitude)}"
                            :zoom="15"
                            map-type-id="terrain"
                            style="width: 100%; height: 500px">
                          <gmap-marker
                              :position="{lat:parseFloat(studioDetails.latitude), lng:parseFloat(studioDetails.longitude)}">
                            <gmap-info-window>
                              <h6>{{ studioDetails.map_location }}</h6>
                            </gmap-info-window>
                          </gmap-marker>
                        </GmapMap>
                      </div>
                    </div>
                    <v-card-text v-else>
                      {{ $t("studios.noContent") }}
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil">
                  <v-card-text v-if="studioDetails.terms === ''">
                    {{ $t("studios.noContent") }}
                  </v-card-text>
                  <v-card-text v-else class="mx-5" v-html="studioDetails.terms"></v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </div>
      <div v-else class="mt-5 details__">
        <v-row>
          <v-col cols="12" md="6">
            <v-skeleton-loader
                class="mx-auto details_img"
                type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <div
                class="d-flex justify-content-start align-items-start gap-5 flex-row"
            >
              <v-skeleton-loader
                  v-bind="attrs"
                  type="actions"
              ></v-skeleton-loader>
              <v-skeleton-loader
                  v-bind="attrs"
                  type="actions"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </div>
      <relatedStudios :relatedStudios="relatedStudios"/>
      <v-dialog v-model="dialogLoginFirst" max-width="500">
        <v-card class="p-3 Login_First py-12">
          <h3 class="font-bold px-16">{{ $t('login-first-to-order') }}</h3>

          <v-card-actions class="d-flex justify-content-center align-items-center flex-row gap-2 mt-5">
            <dialogauth @update:drawer="dialogLoginFirst = $event" :show_btn="true"></dialogauth>
            <button class="btn btn-secondary border-0" @click="dialogLoginFirst = false;">{{ $t("close") }}</button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="orderSummaryDialog" max-width="500">
        <v-card class="p-3 Login_First" v-if="orderSummaryData">
          <h3 class="font-bold px-16">{{ $t('payment-details') }}</h3>
          <hr>
          <div class="details_info d-flex justify-content-start align-start flex-column">
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t("reservation-total-" + orderSummaryData.rent_type + "s") }}</span>
              <span>{{ orderSummaryData.days_hours_count }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t('total-price') }}</span>
              <span>{{ orderSummaryData.total_price }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3"
                 v-if="studioDetails.facilities.length">
              <span>{{ $t('total-facilities-price') }}</span>
              <span>{{ orderSummaryData.total_facilities_price }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t('studios.insurance_amount') }}</span>
              <span>{{ orderSummaryData.insurance_amount }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t('payment-type') }}</span>
              <span>{{ orderSummaryData.pay_type }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t("deposit-money") }}</span>
              <span>{{ orderSummaryData.deposit_money }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t('rest-money') }}</span>
              <span>{{ orderSummaryData.rest_money }}</span>
            </div>
            <div class="d-flex justify-content-start align-start flex-row gap-3">
              <span>{{ $t('total-cost') }}</span>
              <span>{{ orderSummaryData.final_price }}</span>
            </div>
          </div>

          <v-card-actions class="d-flex justify-content-end align-items-center flex-row gap-2 mt-5">
            <v-btn class="btn bg-dark border-0 py-6" @click="orderNow" :disabled="statusOrder" :loading="loadingOffer">
              {{ $t("complete-order") }}
            </v-btn>
            <button class="btn btn-secondary border-0 py-3" @click="orderSummaryDialog = false;">{{
                $t("undo")
              }}
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showAllTags" max-width="500">
        <v-card class="px-6 py-6">
          <div class="row text-center">
            <div class="col">
              <h4>{{ $t('seen-park-chooses') }}</h4>
            </div>
            <div class="col-auto">
              <a href="javascript:void(0);" @click.prevent="showAllTags = false">
                <i class="mdi mdi-close-circle-outline mdi-24px"></i>
              </a>
            </div>
          </div>
          <hr>
          <p>{{ $t('seen-park-chooses-text') }}</p>

          <div class="d-flex flex-column text-start" v-if="studioTags.length">
            <div v-for="tag in studioTags" :key="tag.id">
              <span class="badge px-6 py-2 m-1 fw-medium"
                    style="font-size: 16px;"
                    :style="'background-color:' + tag.color + '25; color:' + tag.color + '; border: 1px solid ' + tag.color">
                <img class="img-fluid" :src="tag.icon_path" :alt="tag.name"
                     style="width: 20px; height: 20px;object-fit: contain;">
                &nbsp;&nbsp;{{ tag.name }}
              </span>
              <p v-html="tag.description"></p>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import dialogauth from "@/components/auth/login.vue";
import LightBox from 'vue-it-bigger';
import axios from "axios";
import relatedStudios from "@/components/services/studios/related.vue";
import {mapGetters} from "vuex";

import('vue-it-bigger/dist/vue-it-bigger.min.css');

export default {
  components: {
    LightBox,
    relatedStudios,
    dialogauth,
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'ar',
      dialogAuth: false,
      dialogLoginFirst: false,
      Openlightbox: false,
      startAt: 0,
      isFavorite: false,
      isUnFavorite: false,
      ratingReviews: 3,
      tab: null,
      rating: 4.3,
      studioDetails: "",
      relatedStudios: [],
      statusFav: "",
      statusUNFav: "",
      statusOrder: false,
      options: {
        loop: true,
        perPage: 3,
        autoplay: true,
        autoplayTimeout: 2000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        perPageCustom: [
          [0, 2],
          [480, 3],
          [768, 3],
          [1000, 3],
          [1200, 6],
        ],
      },
      loadingOffer: false,
      loading: false,
      Startdate: "",
      Startbook: false,
      Enddate: "",
      Endbook: false,
      Starttime: '',
      Endtime: '',
      order_pay_type: 'full_money',
      name: "",
      errorStartDate: [],
      errorEndDate: [],
      errorStartTime: [],
      errorEndTime: [],
      clipboard: null,
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
      media: null,
      start_clock_hours: [
        {text: this.$t('choose-start-date-firstly'), value: ''},
      ],
      end_clock_hours: [
        {text: this.$t('choose-end-date-firstly'), value: ''},
      ],
      coupon: {
        id: null,
        code: '',
        ifSuccess: false,
        errorMessage: '',
        timer: null,
      },
      orderSummaryDialog: false,
      orderSummaryData: null,
      chosenFacilities: [],
      closeOnClick: false,
      chooseDoneShow: false,
      showAllTags: false,
      studioTags: [],
    };
  },
  computed: {
    ...mapGetters(["GetUserData"]),
    Bread() {
      return [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t("Breadcrumb.stdios"),
          disabled: false,
          to: "/spaces",
        },
        {
          text: this.name,
          disabled: true,
          to: "",
        },
      ];
    },
    floatTotalRates() {
      return parseFloat(this.studioDetails.totalRates);
    },
    orderPAYLOAD() {
      return {
        studio_id: this.$route.params.id,
        marketer_id: this.$route.params.marketer_id ?? null,
        from_date: this.Startdate,
        to_date: this.Enddate,
        from_time: this.Starttime,
        to_time: this.Endtime,
        order_pay_type: this.order_pay_type,
        facilities: this.chosenFacilities,
        coupon_id: this.coupon.id
      };
    },
    checkAllFacilities() {
      return this.chosenFacilities.length === this.studioDetails.facilities.length
    },
    checkSomeFacilities() {
      return this.chosenFacilities.length > 0 && !this.checkAllFacilities
    },
    icon() {
      if (this.checkAllFacilities) return 'mdi-close-box'
      if (this.checkSomeFacilities) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  created() {
    const id = this.$route.params.id;
    axios.get("studio-details/" + id)
        .then((res) => {
          let data = res.data.data;

          this.studioDetails = data;
          this.media = data.images;
          this.name = data.name;

          this.relatedStudios = res.data.additional.relatedStudios;
          this.studioTags = res.data.additional.studioTags;
        })
        .catch((err) => {
          if (err.response.data.status === false) {
            this.$router.push({name: "NotFound"});
          }
        });

    let marketerId = this.$route.params.marketer_id ?? null;
    if (marketerId && !this.GetUserData) {
      this.dialogLoginFirst = true;
    }
  },
  methods: {
    allowedDates(val) {
      let dayOfWeek = new Date(val).getDay();
      return this.studioDetails.available_working_days.includes(dayOfWeek);
    },
    openLightbox(index) {
      this.Openlightbox = true;
      this.startAt = index;
    },
    closeLightbox() {
      this.Openlightbox = false;
      this.startAt = 0;
    },
    copyURL() {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.TriggerNotify("success", "تم نسخ الرابط");
    },
    updateLargeImg(imgSrc) {
      this.studioDetails.image_700_url = imgSrc;
    },
    addToFav() {
      this.loading = true;
      const id = this.$route.params.id;
      axios
          .post("add-studio-to-favorite?studio_id=" + id)
          .then((res) => {
            this.isFavorite = true;
            this.loading = false;
            this.isUnFavorite = false;
            this.TriggerNotify("success", res.data.message);
          })
          .catch((e) => {
            if (e.response.data.status === false && !this.GetUserData) {
              this.dialogLoginFirst = true;
            } else {
              this.TriggerNotify("error", e.response.data.error);
              this.TriggerNotify("error", e.response.data.message);
            }
            this.loading = false;
          });
    },
    removeFromFav() {
      this.loading = true;
      const id = this.$route.params.id;
      axios
          .post("remove-studio-from-favorite?studio_id=" + id)
          .then((res) => {
            this.loading = false;
            this.isFavorite = false;
            this.isUnFavorite = true;
            this.TriggerNotify("success", res.data.message);
          })
          .catch((e) => {
            if (e.response.data.status === false && !this.GetUserData) {
              this.dialogLoginFirst = true;
            } else {
              this.TriggerNotify("error", e.response.data.error);
              this.TriggerNotify("error", e.response.data.message);
            }
            this.loading = false;
          });
    },
    toggleFavorite() {
      if (this.isFavorite) {
        this.removeFromFav();
      } else {
        this.addToFav();
      }
    },
    toggleUnFavorite() {
      if (this.isUnFavorite) {
        this.addToFav();
      } else {
        this.removeFromFav();
      }
    },
    getOrderSummary() {
      try {
        this.loadingOffer = true;
        this.statusOrder = true;

        this.errorStartDate = [];
        this.errorEndDate = [];
        this.errorStartTime = [];
        this.errorEndTime = [];

        axios.post('get-order-summary', this.orderPAYLOAD)
            .then((res) => {
              this.loadingOffer = false;
              this.statusOrder = false;

              this.orderSummaryData = res.data.data;
              this.orderSummaryDialog = true;
            })
            .catch((e) => {
              if (e.response.data.status === false && !this.GetUserData) {
                this.dialogLoginFirst = true;
              } else {
                this.TriggerNotify("error", e.response.data.message);
              }
              this.loadingOffer = false;
              this.statusOrder = false;
              let errors = e.response.data.errors;
              if (errors) {
                this.errorStartDate = errors.from_date;
                this.errorEndDate = errors.to_date;
                this.errorStartTime = errors.from_time;
                this.errorEndTime = errors.to_time;
              }
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        this.loadingOffer = false;
        this.statusOrder = false;
      }
    },
    orderNow() {
      try {
        this.loadingOffer = true;
        this.statusOrder = true;

        this.errorStartDate = [];
        this.errorEndDate = [];
        this.errorStartTime = [];
        this.errorEndTime = [];

        axios.post('send-studio-order', this.orderPAYLOAD)
            .then((res) => {
              this.loadingOffer = false;
              this.statusOrder = false;
              this.TriggerNotify("success", res.data.message);
              this.Startdate = "";
              this.Enddate = "";
              this.Starttime = "";
              this.Endtime = "";
              this.order_pay_type = "full_money";
              this.chosenFacilities = [];

              this.coupon.id = null;
              this.coupon.ifSuccess = false;
              this.coupon.code = '';
              this.coupon.errorMessage = '';

              this.orderSummaryData = null;
              this.orderSummaryDialog = false;
            })
            .catch((e) => {
              if (e.response.data.status === false && !this.GetUserData) {
                this.dialogLoginFirst = true;
              } else {
                this.TriggerNotify("error", e.response.data.message);
              }
              this.loadingOffer = false;
              this.statusOrder = false;
              let errors = e.response.data.errors;
              if (errors) {
                this.errorStartDate = errors.from_date;
                this.errorEndDate = errors.to_date;
                this.errorStartTime = errors.from_time;
                this.errorEndTime = errors.to_time;
              }
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        this.loadingOffer = false;
        this.statusOrder = false;
      }
    },
    async openChat(type) {
      try {
        this.loading = true;
        let sendData = new FormData();
        sendData.append('chat_type', 'with_' + type);
        sendData.append('chat_type_id', 1);
        if (type === 'member') {
          sendData.append('chat_type_id', this.studioDetails.member_id ?? 0);
        }

        await axios.post("open-chat", sendData)
            .then((res) => {
              this.loading = false;
              let chat_id = res.data.data.chat_details.chat_id ?? 0;
              this.$router.push({name: 'chatDetails', params: {id: chat_id}});
            })
            .catch((e) => {
              if (e.response.data.status === false && !this.GetUserData) {
                this.dialogLoginFirst = true;
              } else {
                this.TriggerNotify('error', e.response.data.message);
              }
              this.loading = false;
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        this.loading = false;
      }
    },
    getDayWorkTimes(event, place) {
      this.loadingOffer = true;
      const PAYLOAD = {
        studio_id: this.$route.params.id,
        date: event,
      };

      axios.post('get-studio-day-work-times', PAYLOAD)
          .then((res) => {
            let data = res.data.data;

            if (place === 'start') {
              this.start_clock_hours = data;
              if (this.studioDetails.rent_type === 'day' && data.length > 1) {
                this.Starttime = data[1].value;
              }
            } else {
              this.end_clock_hours = data;
              if (this.studioDetails.rent_type === 'day' && data.length > 1) {
                let dataLength = data.length - 1;
                this.Endtime = data[dataLength].value;
              }
            }

            this.loadingOffer = false;
          })
          .catch((e) => {
            this.loadingOffer = false;
            this.TriggerNotify("error", e.response.data.message);
          });
    },
    checkCoupon() {
      this.loadingOffer = true;
      if (this.coupon.timer) {
        clearTimeout(this.coupon.timer);
        this.coupon.timer = null;
      }

      this.coupon.timer = setTimeout(() => {
        const PAYLOAD = {
          studio_id: this.$route.params.id,
          coupon_code: this.coupon.code
        };

        axios.post('check-coupon', PAYLOAD, {headers: {'Accept-Language': this.lang}})
            .then((res) => {
              let data = res.data.data;

              this.coupon.id = data.coupon_id;
              this.coupon.ifSuccess = true;
              this.coupon.errorMessage = '';
              this.TriggerNotify("success", data.message);
            })
            .catch((e) => {
              this.coupon.id = null;
              this.coupon.ifSuccess = false;
              this.coupon.errorMessage = e.response.data.message;
              this.TriggerNotify("error", e.response.data.message);
            });
        this.loadingOffer = false;
      }, 1000);
    },
    toggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllFacilities) {
          this.chosenFacilities = [];
        } else {
          this.chosenFacilities = this.studioDetails.facilities.map(a => a.id);
        }
      })
    },
    toggleCloseMenu() {
      this.chooseDoneShow = false;
      this.closeOnClick = true;
      setTimeout(() => {
        this.closeOnClick = false;
      }, 100);
    }
  },
};
</script>

<style lang="scss" scoped>
.cine_academy_details {
  background-color: #fafafa;
  padding: 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }
}

.__bread {
  box-shadow: none !important;

  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
  }

  .v-breadcrumbs__item {
    color: #000 !important;
  }

  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}

.details_rating {
  margin-bottom: 16px;

  span {
    color: #515c6fde;
    font-size: 14px;
  }
}

.details_desc {
  h1 {
    text-align: start;
    color: #000;
    font-size: 22px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  span {
    font-size: 14px;
  }

  i {
    font-size: 25px;
  }
}

.details_price {
  span {
    color: #000;
    font-weight: 600;
  }

  .__desc {
    span {
      background-color: #e0e0e0;
      color: #4d4d4d !important;
      padding: 3px 9px;
      font-weight: bold;
      border-radius: 4px;
    }
  }
}

.details_btns {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px #1d77b129;
    border-radius: 4px;
    color: #000;
    font-size: 0.875rem;
    height: 44px !important;
    padding: 8px 15px;
    letter-spacing: 0 !important;
    @media (max-width: 580px) {
      width: 100%;
    }
  }

  button:last-of-type {
    background-color: #000 !important;
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }
}

.details_info {
  div {
    padding: 10px 15px;
    width: 100%;

    span:first-of-type {
      width: 25%;
      text-align: start;
      color: #212529;
    }

    span:last-of-type {
      width: 75%;
      text-align: start;
      color: #212529;
    }
  }

  div:nth-child(odd) {
    background-color: #f7f7f7;
  }

  div:nth-child(even) {
    background-color: #fff;
  }
}

.details_img {
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
  }
}

.cine_academy_desc {
  > .v-card {
    border: 1px solid #e0e0e0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .v-tabs {
    border-bottom: 1px solid #e0e0e0;
  }

  .v-slide-group__content {
    flex: initial !important;
  }

  .v-card__text {
    text-align: start !important;
    font-size: 18px;
    line-height: 1.5;
    color: #262626 !important;
  }

  .v-tab.v-tab {
    font-weight: bold !important;
    color: #868e96;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .v-tab--active {
    color: #000 !important;
  }

  .v-tabs-bar {
    height: 55px;
  }
}

.small-Img {
  object-fit: cover;
  border-radius: 12px;
  margin: 0 5px;
}

.__studio_imgs {
  overflow-y: hidden;
  overflow-x: scroll;
  max-height: 95px;
  display: flex;
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-direction: row;

  img {
    width: 130px;
    height: 80px;
    // object-fit: contain;
    object-position: center;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-left: 5px;
    margin-bottom: 5px;
    background-color: #fff;
  }
}

.date_studio {
  span {
    font-weight: bold;
    font-size: 14px;
    color: #212529;
  }
}

.header {
  background-image: url("../../../../public/img/background2.png");
  background-color: #fff;
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.review_users {
  p.comment {
    line-height: 1.7;
    margin-top: 10px;
    color: #242424;
  }

  span.name {
    color: #262626;
    font-weight: bold;
    margin-bottom: 13px !important;
  }

  i {
    font-size: 25px !important;
    padding: 0 !important;
  }
}

fieldset {
  border-color: #e0e0e0 !important;
}

hr {
  color: #e0e0e0 !important;
}

.details__ {
  margin: 48px 0;
}

.work_time_days {
  // border-bottom: 1px solid #e0e0e0;
  // padding-bottom: 10px;
  width: 275px;
  margin-bottom: 24px !important;
  position: relative;

  &:after {
    position: absolute;
    top: 11px;
    right: -15px;
    background-color: #000;
    width: 6px;
    height: 6px;
    content: '';
  }
;
}

.Login_First {
  i {
    color: #e0e0e0;
    font-size: 120px;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  button {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #000;
    color: #fff;
    width: 150px;

    &:last-child {
      background-color: #e0e0e0;
      color: #000;
    }
  }
}

.datetime-error {
  font-size: 12px !important;
  text-align: start;
  display: block;
  color: red !important;
}

.v-select__selection--comma {
  color: #4d4d4d !important;
}
</style>
<style lang="css" scoped>
.rate-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.feature-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
</style>
<style>
.v-date-picker-header {
  direction: initial !important;
}

.v-menu__content {
  z-index: 9 !important;
}

.v-select__selection--comma {
  color: #4d4d4d !important;
}


.input-group {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.input-group input {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.input-group input:focus {
  outline: none;
  box-shadow: none;
  border-radius: 4px !important;
}

.input-group .input-group-text {
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.input-group .input-group-text .custom-success {
  color: #1FC029 !important;
}

.facilities-div .v-input .v-label {
  left: auto !important;
  right: 0 !important;
}

.show-offer {
  background-color: #09BC86;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
}
</style>